import { Grid } from "@mui/material";
import error500 from "assets/images/illustrations/error-500.png";
import error404 from "assets/images/illustrations/error-404.png";
// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
// Authentication layout components
import PageLayout from "components/LayoutContainers/PageLayout";
// NextJS Material Dashboard 2 PRO components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import Link from "next/link";

function Error({ statusCode }) {
  const { d1, d3, d4, d5 } = typography;

  return (
    <PageLayout white>
      <SuiBox my={18} height="calc(100vh - 18rem)">
        <Grid container spacing={3} justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
          <Grid item xs={11} sm={9} container alignItems="center">
            <Grid item xs={12} lg={6}>
              <SuiBox fontSize={{ xs: d5.fontSize, sm: d4.fontSize, md: d3.fontSize, lg: d1.fontSize }} lineHeight={1.2}>
              <SuiTypography variant="inherit" color={statusCode == "404" ? "error" : "warning"} textGradient fontWeight="bold">
                  Error {statusCode}
                </SuiTypography>
              </SuiBox>
              <SuiTypography variant="h2" color="dark" textGradient fontWeight="bold">
                {statusCode == "404" ? "Erm. Page not found" : "Something went wrong"}
              </SuiTypography>
              <SuiBox mt={1} mb={2}>
                <SuiTypography variant="body1" color="text" opacity={1}>
                  We suggest you to go to the homepage while we solve this issue.
                </SuiTypography>
              </SuiBox>
              <SuiBox mt={4} mb={2}>
                <Link href="/" passHref>
                  <SuiButton variant="gradient" color={statusCode == "404" ? "error" : "warning"}>
                    go to homepage
                  </SuiButton>
                </Link>
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <SuiBox component="img" src={statusCode == "404" ? error404.src : error500.src} alt="error-404" width="100%" />
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
    </PageLayout>
  );
}

Error.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404
  return { statusCode };
};

export default Error;
